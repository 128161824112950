import React, {useEffect, useState, useRef} from 'react'
import ErrorWindow from '../../errorWindow/errorWindow'
import ResidenceProofIcon from '../../images/mailProof.png'
import ExampleID from '../../images/exampleID.jpg'
import './residenceProof.css'
import ProgressBar from '../../progressBar/progressBar'

export default function ResidenceProof({display, setResidence, slide}) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Image selection
    const hiddenFileInput = React.useRef(null);

    const handleClickRedirect = event => {
        hiddenFileInput.current.click();
    };


    let [residencePreview, setResidencePreview] = useState()
    let [isFile, setIsFile] = useState(false)

    //////////////////////////////////////////////////////////////
    // handleFileCollect
    //////////////////////////////////////////////////////////////
    function handleResidenceCollect(){
        setIsFile(true)
        setResidencePreview(URL.createObjectURL(hiddenFileInput.current.files[0]))
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('residenceProof', direction)
    }

    //////////////////////////////////////////////////////////////
    // addIDPhoto
    //////////////////////////////////////////////////////////////
    function addResidencePhoto(){

        if(!isFile){
            setErrorDetails({title: "Proof of residency photo missing", description: "Please use the upload button to upload your proof of residency."})
            setErrorDisplay('block')
            setIsFile(false)
            return
        }

        setResidence(hiddenFileInput.current.files[0])
        slide('residenceProof', true)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={ResidenceProofIcon}></img>
            <div className='common_title_subtext'>Now let's validate your residence!</div>
            <ProgressBar page='verifyResidence'></ProgressBar>
            <div className='common_note'>Now we need to collect your proof of residency via either a utility bill or lease agreement which displays the address in which you reside.</div>

            <div className='common_note' style={{marginTop: '1rem'}}>Please capture and upload a clear photo of your utility bill or lease agreement which displays the address with the corners of the paper included. Once you have finished selecting, please continue to the proof of affidavit screen.</div>
           
            {/* Image preview */}
            <img style={{display: isFile ? "block" : "none"}}  className='residence_proof_preview' src={residencePreview} />


            {/* Select image */}
            <div onClick={handleClickRedirect} className='common_button common_submit_button' style={{marginTop: '2rem', marginBottom: '2rem'}}>
            {isFile ? "Change Residency Photo" : "Upload Residency Photo"}
            </div>
            <input type="file"
             ref={hiddenFileInput}
             onChange={()=>handleResidenceCollect()}
             style={{display:'none'}}
            /> 

            <div className='common_button_combination' >
                <div className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
                <div></div>
                <div className='common_button common_submit_button' onClick={()=>addResidencePhoto()}>Continue</div>
            </div>
        </div>
    )
}
