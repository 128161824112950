import React, {useEffect, useState, useRef} from 'react'
import ErrorWindow from '../../errorWindow/errorWindow'
import AffidavitProofIcon from '../../images/affidavitIcon.png'
import './affidavitProof.css'
import ProgressBar from '../../progressBar/progressBar'

export default function AffidavitProof({display, setAffidavit, slide}) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Image selection
    const hiddenFileInput = React.useRef(null);

    const handleClickRedirect = event => {
        hiddenFileInput.current.click();
    };


    let [affidavitPreview, setAffidavitPreview] = useState()
    let [isFile, setIsFile] = useState(false)

    //////////////////////////////////////////////////////////////
    // handleFileCollect
    //////////////////////////////////////////////////////////////
    function handleAffidavitCollect(){
        setIsFile(true)
        setAffidavitPreview(URL.createObjectURL(hiddenFileInput.current.files[0]))
    }

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('affidavitProof', direction)
    }

    //////////////////////////////////////////////////////////////
    // addIDPhoto
    //////////////////////////////////////////////////////////////
    function addAffidavitPhoto(){

        if(!isFile){
            setErrorDetails({title: "Proof of affidavit photo missing", description: "Please use the upload button to upload your proof of office."})
            setErrorDisplay('block')
            setIsFile(false)
            return
        }

        setAffidavit(hiddenFileInput.current.files[0])
        slide('affidavitProof', true)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={AffidavitProofIcon}></img>
            <div className='common_title_subtext'>Verify your application to the office!</div>
            <ProgressBar page='verifyAffidavit'></ProgressBar>
            <div className='common_note'>We need you to provide proof of application for holding office via a copy of the Affidavit of Candidacy.</div>

            <div className='common_note' style={{marginTop: '1rem'}}>Please capture and upload a clear photo of your affidavit which displays the all the information including the notarization with the corners of the paper included. Once you have finished selecting, please continue to the review screen.</div>
           
            {/* Image preview */}
            <img style={{display: isFile ? "block" : "none"}}  className='affidavit_proof_preview' src={affidavitPreview} />


            {/* Select image */}
            <div onClick={handleClickRedirect} className='common_button common_submit_button' style={{marginTop: '2rem', marginBottom: '2rem'}}>
            {isFile ? "Change Affidavit Photo" : "Upload Affidavit Photo"}
            </div>
            <input type="file"
             ref={hiddenFileInput}
             onChange={()=>handleAffidavitCollect()}
             style={{display:'none'}}
            /> 

            <div className='common_button_combination' >
                <div className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
                <div></div>
                <div className='common_button common_submit_button' onClick={()=>addAffidavitPhoto()}>Continue</div>
            </div>
        </div>
    )
}
