import React, {useRef, useEffect, useState} from 'react'
import './myResponse.css'
import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import UserStatement from '../userStatement/userStatement'
import ConfirmSubmission from '../confirmSubmission/confirmSubmission'
import SuccessSubmission from '../successSubmission/successSubmission'



export default function MyResponse({display, information, closeWindow, scrollPosition}) {

    const [officialResponses, setOfficialResponses] = useState([])
    const [skipCount, setSkipCount] = useState(0)


    // Scroll Update
    useEffect(() => {

        if(scrollPosition.y + 1 >= scrollPosition.yMax && display == 'block'){
            console.log("get more")
            getOfficialResponses(information._id, skipCount)
        }
        
    }, [scrollPosition]);


    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const willAddress = useRef()
    const willNotAddress = useRef()

    const [branchType, setBranchType] = useState(null)

    const [officialChoice, setOfficialChoice] = useState(null)

    const [submitLoad, setSubmitLoad] = useState(false)


    const [getLoad, setGetLoad] = useState(false)


    // First Render
    useEffect(() => {
        // Load existing

        if(information._id){

            getOfficialResponses(information._id, 0)

            setTimeout(() => {
                window.scrollTo({ top: -30, behavior: "smooth" })
            }, 1000);

            

        }

        
    }, [information._id]);




    function closeWindowHandler(){
        // Clear values
        resetAll()
        /*
        willNotAddress.current.className = 'my_response_button'
        willAddress.current.className = 'my_response_button' */

        closeWindow()
    }

    function resetAll(){
        setOfficialChoice(null)
        myOfficialResponse.current.value = 'Start typing here...'
        setCharacterCount(0)
        setOfficialResponses([])
        setSkipCount(0)
    }



    async function getOfficialResponses(reportID, skip){

        if(getLoad){
            return
        }

        setGetLoad(true)

        let data = {
            reportID: reportID,
            skip: skip
          }

          await API.post('getOfficialResponses', data)
          .then(res => {
            console.log(res.data)
            setBranchType(res.data.branch)
            setOfficialResponses(prevState => [...prevState, ...res.data.responses])
            setSkipCount(res.data.skip)
            setGetLoad(false)
          })
          .catch(err => { 
              setErrorDetails(err)
              setErrorDisplay('block')
          }) 
    }

    const [confirmationState, setConfirmationState] = useState('none')
    let confirmMessage = 'You will not be able to delete this post once it has been submitted. Please review your edit carefully before submission.'
    let successMessage = 'Your submission was successful!'

    async function submitResponse(direction){
        
        console.log(direction)

        if(direction === 'advance'){

            if(submitLoad){
                return
            }

            let data = {
                reportID: information._id,
                myResponse: myOfficialResponse.current.value,
                skip: skipCount
              }
        
            await API.post('setMyOfficialResponse', data)
            .then(res => {

                console.log(res.data)
                
                // Push new one into array

                myOfficialResponse.current.value = 'Start typing here...'
                setSkipCount(res.data.skip)

                let curOfficialResponse = officialResponses.slice()
                let newArray = []

                newArray.push(res.data.response)

                for(let i = 0; i < curOfficialResponse.length; i++){
                    newArray.push(curOfficialResponse[i])
                }

                setOfficialResponses(newArray)

                setConfirmationState('success')
                

                setTimeout(() => {
                    setSubmitLoad(false)
                }, 1000);
            
            })
            .catch(err => { 
                setErrorDetails(err)
                setErrorDisplay('block')
            })

        } else {
            setConfirmationState('none')
            
        }
    }

    function confirmResponse(){
        setConfirmationState('pending')
    }

    const [characterCount, setCharacterCount] = useState(0)
    const myOfficialResponse = useRef()

    function updateCharacterCount(){
        let curString = myOfficialResponse.current.value
        setCharacterCount(curString.length)
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle focus
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleFocusTextArea(focused){

        if(myOfficialResponse.current.value === 'Start typing here...' && focused){
            myOfficialResponse.current.value = ''
        }

        else if(myOfficialResponse.current.value === '' && !focused){
            myOfficialResponse.current.value = 'Start typing here...'
        }
        
    }

    return (
        <div style={{display: display}} className='my_response_screen_foreground'>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>

            {/* Post Confirmation */}
            <ConfirmSubmission display={confirmationState === 'pending' ? true : false} message={confirmMessage} close={submitResponse}></ConfirmSubmission>
            <SuccessSubmission display={confirmationState === 'success' ? true : false} message={successMessage} close={()=>setConfirmationState('none')}></SuccessSubmission>


            <div className='my_response_section'>
                <UserStatement statementContent={information.topVoteContent} type={information.topVoteContent.type} session={'policyDebate'}></UserStatement>
                <div className='my_response_section_title'>Respond To Constituents</div>
                    {/* 
                    <div className='my_response_button_container'>
                        <div ref={willAddress} style={{marginRight: '.5rem'}} className='my_response_button' onClick={()=>handleChoiceSelection('address')}>I will address this issue by...</div>
                        <div ref={willNotAddress} style={{marginLeft: '.5rem'}} className='my_response_button' onClick={()=>handleChoiceSelection('notAddress')}>I will not address this issue because...</div>
                    </div> */}
            
                    <div className='my_response_disclosure' style={{display: branchType === 'Executive' && branchType != null ? 'block' : 'none'}}>As a member of the executive branch for the {information.topVoteContent.type.toLowerCase()} district, it is expected that you provide various status updates and reports regarding this statement.</div>
                    <div className='my_response_disclosure' style={{display: branchType === 'Legislative' && branchType != null ? 'block' : 'none'}}>As a member of the legislative branch for the {information.topVoteContent.type.toLowerCase()} district, it is expected that you provide various status updates or bill information regarding this statement.</div>
                    <div className='my_response_disclosure' style={{display: branchType === 'Judicial' && branchType != null ? 'block' : 'none'}}>As a member of the judicial branch for the {information.topVoteContent.type.toLowerCase()} district, it is expected that you provide various status updates and case numbers regarding this statement.</div>

                    <textarea maxlength="280" ref={myOfficialResponse} className='my_response_text_area' onChange={()=>updateCharacterCount()} onFocus={()=>handleFocusTextArea(true)} onBlur={()=>handleFocusTextArea(false)}>Start typing here...</textarea>
                    <div className='my_response_character_count'>{characterCount}/280</div>

                    <div style={{marginTop: '2rem'}} className='my_response_button' onClick={()=>confirmResponse()}>Submit</div>
                    <div style={{marginTop: '1rem'}} className='my_response_button' onClick={()=>closeWindowHandler()}>Cancel</div>
            </div>

            {
                officialResponses.map((response) => 
                <div>
                    <div className='my_response_section'>
                        
                        <div className='my_response_name_and_title_grid'>
                            <img src={response.profilePictureURL} className="my_response_name_and_title_image"></img>
                            <div className='my_response_name_and_title_container'>
                                <div className='my_response_name'>{response.name}</div>
                                <div className='my_response_title'>{response.title}</div>
                            </div>
                            
                        </div>
                        <div className='my_response_posted_date'>{response.readableDate}</div>

                        <div className='my_response_response'>{response.response}</div>                       
                    </div>
                </div>    
            )}
        </div>

    )
}
