import React, {useState, useEffect} from 'react'
import './userStatement.css'
import topPriority from '../images/certifiedReport.png'
import MetricType from '../metricType/metricType'


export default function UserStatement({statementContent, type, session, handleSelection}) {

    function selectionHandler(id, districtType){
        handleSelection(id, districtType)
    }

    //const [canIVote, setCanIVote] = useState(statementContent.available)



  return (
    <div className="fade-in-left">

        <div style={{display: session === 'policyDebate' ? 'block' : 'none'}} className='top_priority_badge_section'>
            <img className='top_priority_badge' src={topPriority}></img>
            <div className='top_priority_text'>{type} Elected Statement</div>
            
        </div>

        
        
        <div className={session === 'full' ? 'user_statement_bubble round' : 'user_statement_bubble tri-right btm-left-in round'}>
            {/* <div className='user_statement_anonymous'>Anonymous {type} Constituent writes:</div> */}
            {/* DATE CREATED */}
            <div className='user_statement_creation_text'>{statementContent.postedDate}</div>
            {/* STATEMENT */}
            <div style={{marginBottom: '1rem'}}>{statementContent.statement}</div>
            

            {/* METRICS */}
            <div className='metric_columns'>
                <MetricType type={'RPV'} count={statementContent.registeredPriorityVotes}></MetricType>
                <div></div>
                <MetricType type={'UPV'} count={statementContent.unregisteredPriorityVotes}></MetricType>
            </div>

            {/* METRICS */}
            <div className='metric_columns' style={{display: session === 'policyDebate'|| session === 'full'  ? 'grid' : 'none'}}>
                <MetricType type={'RAV'} count={statementContent.registeredUpvotes}></MetricType>
                <div></div>
                <MetricType type={'UAV'} count={statementContent.unregisteredUpvotes}></MetricType>
            </div>

            {/* METRICS */}
            <div className='metric_columns' style={{display: session === 'policyDebate' || session === 'full' ? 'grid' : 'none'}}>
                <MetricType type={'RDV'} count={statementContent.registeredDownvotes}></MetricType>
                <div></div>
                <MetricType type={'UDV'} count={statementContent.unregisteredDownvotes}></MetricType>
            </div>
            
            {/* VOTE */}
            <div style={{display: statementContent.available ? 'block' : 'none'}} className='mark_as_priority_button' onClick={()=>selectionHandler(statementContent._id, type)}>Select As Top Priority</div>
        </div>


        


        
    </div>
  )
}
