import React, {useEffect, useState, useRef} from 'react'
import './payment.css'
import ErrorWindow from '../../errorWindow/errorWindow'
import ProgressBar from '../../progressBar/progressBar'
import CreditCard from '../../images/creditCard.png'
import CheckoutPage from '../../utilities/expressCheckout';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import trialPeriod from '../../images/trialPeriod.png'


export default function Payment({display, slide, officialData, clientSecret}) {

    // Stripe related variables //////////////////////////////////////////////////////////////
    let proposedEnd = new Date()
    proposedEnd.setDate(proposedEnd.getDate() + 90)

    let proposedMonth = proposedEnd.getMonth()
    let proposedYear = proposedEnd.getFullYear()
    let proposedDay = proposedEnd.getDate()

    // Error Related//////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    //////////////////////////////////////////////////////////////////////////////////////////



    // Stripe related variables //////////////////////////////////////////////////////////////


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        var stripeKey = 'pk_test_51Mj9uAHIBORgtzB4MnOCt2XrouzgPbkrqs1JlFAKAPutZ2Y9E8fHq44nLx2JFCohsRiVVfdQPvNni4Hufu3hS72T00UYlKIpGl'
      } else {
        // production code
        var stripeKey = 'pk_live_51Mj9uAHIBORgtzB4m5f7HSspgXR6aqqvD6USVh9r4nL9cmFZPWmSPqIwOBsYe9V79EmZOaQB8Bajohi4JFepm09J00QsXMTpiA';
      }


    const stripePromise = loadStripe(stripeKey);    

    const stripeOptions = {
  
        clientSecret,
        // Customizable with appearance API.
        appearance: {
            theme: 'stripe'
        }
      };
    //////////////////////////////////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('payment', direction)
    }


    //////////////////////////////////////////////////////////////
    // Get Ordinal
    //////////////////////////////////////////////////////////////
    const getOrdinalNum = (number) => {
        let selector;
      
        if (number <= 0) {
          selector = 4;
        } else if ((number > 3 && number < 21) || number % 10 > 3) {
          selector = 0;
        } else {
          selector = number % 10;
        }
      
        return number + ['th', 'st', 'nd', 'rd', ''][selector];
    };

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];




    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={CreditCard}></img>
            <div className='common_title_subtext'>Almost there!<br></br> Let's set up your payment!</div>

            <ProgressBar page='payment'></ProgressBar>

            <div className='payment_trial_box'>
                <img className='common_icon_center' src={trialPeriod}></img>
                <div className='payment_trial_title'>90 Day Trial Starts Today!</div>
                <div className='payment_trial_description'>You will not get charged until {month[proposedMonth]} {getOrdinalNum(proposedDay)}, {proposedYear}, or 90 days after approval of your account!</div>
            </div>
            

            <div className='payment_monthly_price'>$2.49</div>
            <div className='payment_monthly_subtitle'>Monthly Membership</div>

            {clientSecret && (
            <Elements options={stripeOptions} stripe={stripePromise}>
                <CheckoutPage checkoutData={officialData} clientSecret={clientSecret}/>
            </Elements>
      )}

        <div className='payment_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>

        </div>
    )
}
