import React, {useRef} from 'react'
import './loginPrompt.css'
import { useNavigate } from 'react-router-dom';

export default function LoginPrompt({loginHandler}) {
  const navigate = useNavigate()
  const phoneNumber = useRef()
  const password = useRef()

  const handleKeyDown = event => {
      if(event.key === 'Enter'){
          loginHandler(phoneNumber.current.value, password.current.value)
      }
    };

  function logIn(){
      loginHandler(phoneNumber.current.value, password.current.value)
      phoneNumber.current.value = ''
      password.current.value = ''
  }

  function openPasswordReset(){
    navigate('/passwordReset')
  }

  return (
    <div className='form_box'>
        <form action="#">
            <div className="input_box">
              <input ref={phoneNumber} onKeyDownCapture={handleKeyDown} type="text" placeholder="Phone Number" required/>
              <div className="icon"><i className="fa fa-phone"></i></div>
            </div>
            <div className="input_box">
                <input ref={password} onKeyDownCapture={handleKeyDown} type="password" placeholder="Password" required/>
                <div className="icon"><i className="fa fa-lock"></i></div>
            </div>
        </form>

        <div className="common_button_combination">
          <div className='login_button' onClick={() => navigate('/signUp')}>Sign Up</div>
          <div></div>
          <div className='login_button' onClick={() => logIn()}>Log In</div>
        </div>
        
    
      <div className="forgot_password_button" onClick={()=>openPasswordReset()}>Forgot password? Click here to reset password</div>
    </div>
  )
}
