import React, {useState, useRef} from 'react'
import API from "../../utilities/api"
import ErrorWindow from '../../errorWindow/errorWindow'
import '../passwordReset.css'

export default function VerifyCode({progressionState, advanceOrWithdraw, passwordData}) {
    const [disableButton, setDisableButton] = useState(false)

    const code = useRef()

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    // Resend code
    async function resendCode(){
      await API.post('resetPasswordValidate', passwordData)
      .catch(err => { 
          setErrorDetails(err)
          setErrorDisplay('block')
      })
    }
          


    async function continueToNextScreen(){

      let codeData = {
        code: code.current.value,
      }

      let data = {...codeData, ...passwordData}

      await API.post('resetPasswordCode', data)
      .then(res => {
          advanceOrWithdraw('verifyCode', true)
      })
      .catch(err => { 
          setErrorDetails(err)
          setErrorDisplay('block')
      })

    }

  return (
    <div style={{display : progressionState == 'verifyCode' ? 'block' : 'none', marginTop: "20px"}}>
      <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
      <div className='common_note'>A text message should be arriving to your phone with a code which you will need to enter in the field below to complete your password reset.</div>
      <div className='password_reset_code_resend' onClick={()=>resendCode()}>Didn't receive code? Click here to try resending</div>

      <div className='form_box'>
        <form action="#">
          <div className="password_reset_input_box">
              <input ref={code} type="text" placeholder="Verification Code" required/>
              <div className="password_reset_icon"><i className="fa fa-key"></i></div>
          </div>
        </form>
      </div>

        <div className='common_button_combination' style={{marginTop: '20px'}}>
            <div className='common_button common_cancel_button' isDisabled={disableButton} onClick={()=>advanceOrWithdraw('verifyCode',false)}>Back</div>
            <div></div>
            <div className='common_button common_submit_button' isDisabled={disableButton} onClick={()=>continueToNextScreen()}>Next</div>
        </div>
    </div>
  )
}
