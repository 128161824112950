import React, {useEffect, useState, useRef} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import './invitationCode.css'
import ErrorWindow from '../../errorWindow/errorWindow'
import ProgressBar from '../../progressBar/progressBar'
import Passcode from '../../images/passcode.png'



export default function InvitationCode({display, setData, slide}) {

    const location = useLocation();
    const [queryParameters] = useSearchParams()

    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      baseAddress = 'http://dev-official-back.voterstack.com:5020/'
    } else {
      // production code
      baseAddress = 'https://obe.voterstack.com:5020/'
    }



    useEffect(() => {
        window.scrollTo(0, 0)
        let activationCode = queryParameters.get("activationCode")

        if(activationCode){
            invitationCode.current.value = activationCode
            validateInvitationCode()
        }

    }, [])

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function setDataHandler(response){
        setData(response)
    }


    function closeError(){
        setErrorDisplay('none')
    }

    const invitationCode = useRef()


    //////////////////////////////////////////////////////////////
    // validateInvitationCode
    //////////////////////////////////////////////////////////////
    async function validateInvitationCode(){

        await fetch(baseAddress + "validateInvitationCode", {
            method: "POST",
            headers: {
            "content-type" : 'application/json'
            },
            body: JSON.stringify({
                invitationCode: invitationCode.current.value
            })
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(res => {

            res.invitationCode = invitationCode.current.value
            console.log(res)
            setDataHandler(res)
            slideHandler(true)
        })
        .catch(error => {
            console.log(error)
            setErrorDetails(error)
            setErrorDisplay('block')
        })
    }



    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    function slideHandler(direction){
        slide('invitationCode', direction)
    }

    return (
        <div style={{display: display ? 'block' : 'none'}}>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <img className='common_icon_center' src={Passcode}></img>
            <div className='common_title_subtext'>Welcome!<br></br> Let's get your account set up!</div>

            <ProgressBar page='invitationCode'></ProgressBar>

            <div className='common_note'>In order to verify your invitation to sign up for your district as an official, you will need to provide your verification code sent to your email. Please use the 64 character string to verify your invitation.</div>

            <div className='common_input_text' style={{textAlign: 'center'}}>Invitation Code</div>
            <input ref={invitationCode} className='common_input' style={{marginTop: '1rem', marginBottom: '1rem'}}></input>

            <div className='common_button_combination' >
                <div className='common_button common_cancel_button' onClick={()=>slideHandler(false)} >Cancel</div>
                <div></div>
                <div className='common_button common_submit_button' onClick={()=>validateInvitationCode()}>Continue</div>
            </div>
        </div>
    )
}
