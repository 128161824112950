import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './signUp.css'
import GeneralInfo from '../generalInfo/generalInfo'
import ErrorWindow from '../errorWindow/errorWindow'
import InvitationCode from './invitationCode/invitationCode'
import BasicInformation from './basicInformation/basicInformation'
import IdentityProof from './identityProof/identityProof'
import ResidenceProof from './residenceProof/residenceProof'
import AffidavitProof from './affidavitProof/affidavitProof'
import ReviewInformation from './reviewInformation/reviewInformation'
import Payment from './payment/payment'




export default function SignUp() {

    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      baseAddress = 'http://dev-official-back.voterstack.com:5020/'
    } else {
      // production code
      baseAddress = 'https://obe.voterstack.com:5020/'
    }

    // Base
    const navigate = useNavigate()


    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    const [slide, setSlide] = useState({
        invitationCode: true,
        basicInformation: false,
        identityProof: false,
        affidavitProof: false,
        review: false,
        payment: false
    })

    const [clientSecret, setClientSecret] = useState(null)

    const formData = new FormData()

    // Default value for official
    const [officialObject, setOfficialObject] = useState({
            _id: "",
            districtName: "",
            districtType: "",
            districtPhotoURL: "",
            firstName: "",
            lastName: "",
            officialTitle: "",
            termStart: 0,
            termEnd: 0,
            branchType: "",
            officeEmailAddress: "",
            officePhoneNumber: "",
            currentMembershipStatus: "",
            responsibilitiesList: "",
            profilePictureURL: "",
            birthday: null,
            dateOfBirth: null,
            gender: null,
            ethnicity: null,
            personalEmailAddress: null,
            personalPhoneNumber: null,
            password: null,
            addressLineOne: null,
            addressLineTwo: null,
            city: null,
            state: null,
            zipCode: null,
            residenceProofPhoto: null,
            idProofPhoto: null,
            affidavitProofPhoto: null,
            residenceProofPhotoURL: null,
            idProofPhotoURL: null,
            affidavitProofPhotoURL: null,
            agreement: null
        })


    //////////////////////////////////////////////////////////////
    // modifyOfficialObject
    //////////////////////////////////////////////////////////////
    function updateOfficialBasicInformation(official){
        let currentOfficial = officialObject
        currentOfficial.dateOfBirth = official.dateOfBirth
        currentOfficial.gender = official.gender
        currentOfficial.ethnicity = official.ethnicity
        currentOfficial.personalPhoneNumber = official.personalPhoneNumber
        currentOfficial.personalEmailAddress = official.personalEmailAddress
        currentOfficial.password = official.password
        currentOfficial.addressLineOne = official.addressLineOne
        currentOfficial.addressLineTwo = official.addressLineTwo
        currentOfficial.city = official.city
        currentOfficial.state = official.state
        currentOfficial.zipCode = official.zipCode
        currentOfficial.birthday = official.birthday
        currentOfficial.agreement = official.agreement

        setOfficialObject(currentOfficial)
    }

    //////////////////////////////////////////////////////////////
    // advanceSlide
    //////////////////////////////////////////////////////////////
    function moveSlide(current, direction){
        window.scrollTo(0, 0)
        
        switch(current){
            case 'invitationCode':
                if(direction){  
                    setSlide({
                        invitationCode: false, 
                        basicInformation: true, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                } else {
                    exitSignUp()
                }
                break
            case 'basicInformation':
                if(direction){
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: true,
                        residenceProof: false,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                } else {
                    setSlide({
                        invitationCode: true, 
                        basicInformation: false, 
                        identityProof: false, 
                        affidavitProof: false,
                        review: false,
                        payment: false})
                } 
                break
            case 'identityProof':
                if(direction){
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: true,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                } else {
                    setSlide({
                        invitationCode: false, 
                        basicInformation: true, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                }   
                break
            case 'residenceProof':
                if(direction){
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: true,
                        review: false,
                        payment: false})
                } else {
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: true,
                        residenceProof: false,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                }   
                break
            case 'affidavitProof':
                if(direction){
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: false,
                        review: true,
                        payment: false})
                } else {
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: true,
                        affidavitProof: false,
                        review: false,
                        payment: false})
                }   
                break
            case 'review':
                if(direction){
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: false,
                        review: false,
                        payment: true
                    })
                } else {
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: true,
                        review: false,
                        payment: false})
                    break
                }
            case 'payment':
                if(direction){
                    // Redirection will happen here
                } else {
                    setSlide({
                        invitationCode: false, 
                        basicInformation: false, 
                        identityProof: false,
                        residenceProof: false,
                        affidavitProof: false,
                        review: true,
                        payment: false})
                    break
                }
            default:
                return
        }

    }

    //////////////////////////////////////////////////////////////
    // SetIdentityHandler
    //////////////////////////////////////////////////////////////
    function setIdentityHandler(file){
        let currentOfficial = officialObject
        currentOfficial.idProofPhoto = file
        currentOfficial.idProofPhotoURL = URL.createObjectURL(file)
        setOfficialObject(currentOfficial)
    }

    //////////////////////////////////////////////////////////////
    // SetResidenceHandler
    //////////////////////////////////////////////////////////////
    function setResidenceHandler(file){
        let currentOfficial = officialObject
        currentOfficial.residenceProofPhoto = file
        currentOfficial.residenceProofPhotoURL = URL.createObjectURL(file)
        setOfficialObject(currentOfficial)

    }

    //////////////////////////////////////////////////////////////
    // SetAffidavit
    //////////////////////////////////////////////////////////////
    function setAffidavitHandler(file){
        let currentOfficial = officialObject
        currentOfficial.affidavitProofPhoto = file
        currentOfficial.affidavitProofPhotoURL = URL.createObjectURL(file)
        setOfficialObject(currentOfficial)
    }

    //////////////////////////////////////////////////////////////
    // exitSignUp
    //////////////////////////////////////////////////////////////
    function exitSignUp(){
        navigate('/')
    }

    //////////////////////////////////////////////////////////////
    // Create Account
    //////////////////////////////////////////////////////////////
    async function createAccount(){

        formData.append("proof", officialObject.idProofPhoto)
        formData.append("proof", officialObject.residenceProofPhoto)
        formData.append("proof", officialObject.affidavitProofPhoto)
        formData.append("dateOfBirth", officialObject.dateOfBirth)
        formData.append("gender", officialObject.gender)
        formData.append("ethnicity", officialObject.ethnicity)
        formData.append("personalPhoneNumber", officialObject.personalPhoneNumber)
        formData.append("personalEmailAddress", officialObject.personalEmailAddress)
        formData.append("addressLineOne", officialObject.addressLineOne)
        formData.append("addressLineTwo", officialObject.addressLineTwo)
        formData.append("city", officialObject.city)
        formData.append("state", officialObject.state)
        formData.append("zipCode", officialObject.zipCode)
        formData.append("password", officialObject.password)
        formData.append("invitationCode", officialObject.invitationCode)
        formData.append("agreement", officialObject.agreement)
        
        return await fetch(baseAddress + "setOfficial", {
            method: "POST",
            body: formData
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(returnData => {
            console.log(returnData)
            setClientSecret(returnData.clientSecret)
            return true
        })
        .catch(error => {
            console.log(error)
            setErrorDetails(error)
            setErrorDisplay('block')
            return false
        }) 
    }

  return (
    <div className="screen_background background_image">
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <div className="signup_screen_foreground">
            <div className="signup_inner_section">
                <InvitationCode
                    display={slide.invitationCode}
                    setData={setOfficialObject}
                    slide={moveSlide}>
                </InvitationCode>

                <BasicInformation
                    display={slide.basicInformation}
                    data={officialObject}
                    setData={updateOfficialBasicInformation}
                    slide={moveSlide}>
                </BasicInformation>

                <IdentityProof
                    display={slide.identityProof}
                    slide={moveSlide}
                    setIdentity={setIdentityHandler}>
                </IdentityProof>

                <ResidenceProof
                    display={slide.residenceProof}
                    slide={moveSlide}
                    setResidence={setResidenceHandler}>
                </ResidenceProof>

                <AffidavitProof
                    display={slide.affidavitProof}
                    slide={moveSlide}
                    setAffidavit={setAffidavitHandler}>
                </AffidavitProof>

                <ReviewInformation
                    display={slide.review}
                    slide={moveSlide}
                    information={officialObject}
                    createAccount={createAccount}>
                    
                </ReviewInformation>

                <Payment
                    display={slide.payment}
                    slide={moveSlide}
                    officialData={officialObject}
                    clientSecret={clientSecret}
                    >
                </Payment>
            </div>
        </div>
    </div>
  )
}
