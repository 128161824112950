import React, {useState, useEffect} from 'react'
import ReviewIcon from '../../images/reviewInformation.png'
import './reviewInformation.css'
import ProgressBar from '../../progressBar/progressBar'

export default function ReviewInformation({display, information, slide, createAccount}) {

    useEffect(() => {
        window.scrollTo(0, 0)
        }, [])

    const [disableButton, setDisableButton] = useState(false)

    //////////////////////////////////////////////////////////////
    // slideHandler
    //////////////////////////////////////////////////////////////
    async function slideHandler(direction){
        if(direction){
            setDisableButton(true)
            let status = await createAccount()
            console.log(information)
            if(!status){
              return
            }
        }
        slide('review', direction)
    }

    //////////////////////////////////////////////////////////////
    // formatDate
    //////////////////////////////////////////////////////////////
    function formatDate(variableDate){
        let month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let myDate = new Date(variableDate)

        let theDate = myDate.getDate()
        let theMonth = myDate.getMonth()
        let theYear = myDate.getFullYear()

        return month[theMonth] + ' ' + getOrdinalNum(theDate) + ', ' + theYear

    }

    //////////////////////////////////////////////////////////////
    // Get Ordinal
    //////////////////////////////////////////////////////////////
    const getOrdinalNum = (number) => {
        let selector;
      
        if (number <= 0) {
          selector = 4;
        } else if ((number > 3 && number < 21) || number % 10 > 3) {
          selector = 0;
        } else {
          selector = number % 10;
        }
      
        return number + ['th', 'st', 'nd', 'rd', ''][selector];
    };

  return (
    <div style={{display: display ? 'block' : 'none'}}>
            <img className='common_icon_center' src={ReviewIcon}></img>
            <div className='common_title_subtext'>Time to review!</div>
            <ProgressBar page='reviewInformation'></ProgressBar>
            <div className='common_note'>Please verify all of the information in which you entered is correct. If you need to make an edit, please hit the back button to the correct form to make your edits.</div>

            <div className='common_note' style={{marginTop: '.5rem'}}>Once you click checkout and have confirmed payment on the following screen, your information will be reviewed by a VoterStack representative to determine identification, residential, affidavit authenticity.</div>
            
            <img src={information.profilePictureURL} alt="Official Photo Missing" className='review_information_profile_photo'></img>

            <div className='review_information_subtitle_header'>Personal Information</div>

            <div className='common_input_text'>Name:</div>
            <div className='review_information_user_entry'>{information.firstName + ' ' + information.lastName}</div>

            <div className='common_input_text'>Date Of Birth:</div>
            <div className='review_information_user_entry'>{information.birthday}</div>

            <div className='common_input_text'>Residential Address:</div>
            <div className='review_information_user_entry'>{information.addressLineOne}</div>
            <div className='review_information_user_entry'>{information.addressLineTwo}</div>
            <div className='review_information_user_entry'>{information.city}</div>
            <div className='review_information_user_entry'>{information.state}</div>
            <div className='review_information_user_entry'>{information.zipCode}</div>

            <div className='common_input_text'>Personal Email Address:</div>
            <div className='review_information_user_entry'>{information.emailAddress}</div>

            <div className='common_input_text'>Personal Phone Number:</div>
            <div className='review_information_user_entry'>{information.phoneNumber}</div>

            <div className='common_input_text'>Gender:</div>
            <div className='review_information_user_entry'>{information.gender}</div>

            <div className='common_input_text'>Ethnicity:</div>
            <div className='review_information_user_entry'>{information.ethnicity}</div>

            <div className='common_input_text'>Identification Proof:</div>
            <img className='review_information_proof_photo' src={information.idProofPhotoURL}/>

            <div className='common_input_text'>Residential Proof:</div>
            <img className='review_information_proof_photo' src={information.residenceProofPhotoURL}/>

            <div className='review_information_subtitle_header'>Controlling Office Information</div>

            <div className='common_input_text'>Government Position:</div>
            <div className='review_information_user_entry'>{information.officialTitle}</div>

            <div className='common_input_text'>Government Branch:</div>
            <div className='review_information_user_entry'>{information.branchType}</div>

            <div className='common_input_text'>District Type:</div>
            <div className='review_information_user_entry'>{information.districtType}</div>

            <div className='common_input_text'>District Name:</div>
            <div className='review_information_user_entry'>{information.districtName}</div>

            <div className='common_input_text'>Term Start:</div>
            <div className='review_information_user_entry'>{formatDate(information.termStart)}</div>

            <div className='common_input_text'>Term End:</div>
            <div className='review_information_user_entry'>{formatDate(information.termEnd)}</div>

            <div className='common_input_text'>Office Phone Number:</div>
            <div className='review_information_user_entry'>{information.officePhoneNumber}</div>

            <div className='common_input_text'>Office Email Address:</div>
            <div className='review_information_user_entry'>{information.officeEmailAddress}</div>

            <div className='common_input_text'>Affidavit Proof:</div>
            <img className='review_information_proof_photo' src={information.affidavitProofPhotoURL}/>


            <div className='common_button_combination' >
                <div className='common_button common_cancel_button' isDisabled={disableButton} onClick={()=>slideHandler(false)} >Back</div>
                <div></div>
                <div className='common_button common_submit_button' isDisabled={disableButton} onClick={()=>slideHandler(true)}>Checkout</div>
            </div>
        </div>
  )
}
